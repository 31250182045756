import React from 'react';
import BoardDetail from 'components/board/boardDetail';

export default function NoticeDetail() {
  const boardType = 'videoGallery';
  return (
    <BoardDetail
      pageTitle="우수 영상관"
      visualProps={{
        category: 'notice',
        title: '우수 영상관',
        eng: 'DREAMS VIDEO GALLERY'
      }}
      boardType={boardType}
    />
  );
}
