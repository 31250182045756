import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    device: null,
    selectedLocal: '전체',
    selectedInfoYear: '2024년',
    selectedLocalYear: '2023년',
    selectedAwardYear: '2024년',
    asideMenu: 1,
    isErrorDialogOpen: false,
    errorDialogStatus: ''
  },
  reducers: {
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setSelectedLocal: (state, action) => {
      state.selectedLocal = action.payload;
    },
    setSelectedInfoYear: (state, action) => {
      state.selectedInfoYear = action.payload;
    },
    setSelectedLocalYear: (state, action) => {
      state.selectedLocalYear = action.payload;
    },
    setSelectedAwardYear: (state, action) => {
      state.selectedAwardYear = action.payload;
    },
    setSelectAsideMenu: (state, action) => {
      state.asideMenu = action.payload;
    },
    setIsErrorDialogOpen: (state, action) => {
      state.isErrorDialogOpen = action.payload;
    },
    setErrorDialogStatus: (state, action) => {
      state.errorDialogStatus = action.payload;
    }
  }
});

export const {
  setDevice,
  setSelectedLocal,
  setSelectedInfoYear,
  setSelectedLocalYear,
  setSelectedAwardYear,
  setSelectAsideMenu,
  setIsErrorDialogOpen,
  setErrorDialogStatus
} = commonSlice.actions;
export const selectDevice = state => state.common.device;
export const selectSelectedLocal = state => state.common.selectedLocal;
export const selectSelectedInfoYear = state => state.common.selectedInfoYear;
export const selectSelectedLocalYear = state => state.common.selectedLocalYear;
export const selectSelectedAwardYear = state => state.common.selectedAwardYear;
export const selectAsideMenu = state => state.common.asideMenu;
export const selectIsErrorDialogOpen = state => state.common.isErrorDialogOpen;
export const selectErrorDialogStatus = state => state.common.errorDialogStatus;
export default commonSlice;
