import React from 'react';
import { Container, Box, Text } from '@radix-ui/themes';
import Visual from 'components/common/visual/index';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

import './style.scss';

export default function VideoGallery() {
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  return (
    <>
      <Visual category="solution" title="솔루션 뱅크" eng="Dreams Solution Bank" />
      <section className="solution-intro-wrap">
        <Container>
          <BreadcrumbsBox />
          <PageTitle title="솔루션 뱅크 사업 소개" />
          <Box mb="var(--space-20)">
            <Text as="p" align="center" mb="Var(--space-6)" className={isMobile ? 'title5' : 'title4'}>
              사업목적
            </Text>
            <Text as="p" align="center" className={`font-basic body${isMobile ? '2' : '1'}`}>
              디지털혁신 개발로 지역 이슈를 해결하는 전과정을 관리하고 이해관계자의 접근성과 상시 모니터링체계를 구축하여 자원의 중복투자를
              막고 효율적인 개발관리
            </Text>
          </Box>

          <Box>
            <Box className="goal-item gps">
              <Text align="center" as="p" className={`font-basic ${isMobile ? 'sub-title1' : 'title7'}`}>
                지역이슈분류 토픽 분야
              </Text>
            </Box>
            <Box className="goal-item issue">
              <Text align="center" as="p" className={`font-basic ${isMobile ? 'sub-title1' : 'title7'}`}>
                이슈정의
              </Text>
            </Box>
            <Box className="goal-item resolve">
              <Text align="center" as="p" className={`font-basic ${isMobile ? 'sub-title1' : 'title7'}`}>
                해결과정
              </Text>
            </Box>
            <Box className="goal-item data">
              <Text align="center" as="p" className={`font-basic ${isMobile ? 'sub-title1' : 'title7'}`}>
                프로토타입 생성·파생 데이터
              </Text>
            </Box>
            <Box className="goal-item crowd">
              <Text align="center" as="p" className={`font-basic ${isMobile ? 'sub-title1' : 'title7'}`}>
                크라우드소싱
              </Text>
            </Box>
            <Box className="goal-item goal">
              <Text align="center" as="p" className={`font-basic ${isMobile ? 'sub-title1' : 'title7'}`}>
                이슈박물관 아카이빙 단계로 이어지며 <Text className="font-info">디지털 혁신개발로 지역 이슈를 해결</Text>
                하는 생명주기 관리
              </Text>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
}
