import React from 'react';
import { Box, Card, Flex, Inset, Text } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';

import './style.scss';

export const ImageTileItem = ({ data }) => {
  const navigate = useNavigate();

  return (
    <>
      {data?.map((el, i) => (
        <Card key={i} className="board-item type-image-tile" asChild onClick={() => navigate(`/solution/projectList/detail/${el.id}`)}>
          <Flex direction="column">
            <Inset clip="padding-box" side="top" className="card-thumbnail">
              <img
                src={el.thumbnail?.fileUrl || require('../../../assets/images/pages/solution/temp-solution-list-img.png')}
                alt="썸네일 이미지"
                className="data-img"
              />
              <Box className="chip">
                <Text as="span" className="sub-title3">
                  {el.region}
                </Text>
              </Box>
            </Inset>

            <Box className="card-box" px="var(--space-7)" py="var(--space-7)" flexGrow="1">
              <Box mb="var(--space-5)" className="card-header">
                <Flex align="center" gap="var(--space-2)" mb="var(--space-2)" className="card-step">
                  <img src={require(`assets/images/pages/solution/icon-step-${el.stage.split('단계')[0]}.svg`)} alt="아이콘 이미지" />
                  <Text as="p" className="sub-title2">
                    {el.stage === '1단계' ? '지역현안' : el.stage === '2단계' ? '아이디어' : el.stage === '3단계' ? '프로젝트' : '아카이브'}
                  </Text>
                </Flex>
                <Text as="p" className="title7">
                  {el.title}
                </Text>
              </Box>

              <Box className="card-footer" pt="var(--space-5)">
                <Flex gap="var(--space-10)" align="center">
                  <Text align="right" className="body3">
                    출처 : {el.source}
                  </Text>
                  <Box className="view body2">{el.viewCount}</Box>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Card>
      ))}
    </>
  );
};
