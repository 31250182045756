import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import BoardImage from 'components/board/boardImage';

import { instance } from 'api/axios.instance';

// 솔루션뱅크 > 프로젝트 목록
export default function ProjectList() {
  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [projectRequestList, setProjectRequestList] = useState({ list: [], pagination: {} }); // 프로젝트 목록
  const [loading, setLoading] = useState(true); // 로딩 상태

  // 프로젝트 목록 조회 API
  const fetchProjectRequestList = useCallback(async () => {
    const searchTypeMap = {
      제목: 'title',
      내용: 'content'
    };

    setLoading(true);
    try {
      const response = await instance('/project/whole/list', {
        method: 'POST',
        data: {
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          pageSize: 8,
          searchType: searchTypeMap[searchParams.get('searchType')] || '', // 검색 카테고리
          search: searchParams.get('search') || '', // 검색어
          region: searchParams.get('region') || '' // 지역
        }
      });
      setProjectRequestList({ pagination: response.data.data.pagination, list: response.data.data.projects });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchProjectRequestList();
  }, [searchParams, fetchProjectRequestList]);

  return (
    <BoardImage
      pageTitle="프로젝트 목록"
      visualProps={{
        category: 'solution',
        title: '솔루션 뱅크',
        eng: 'Solution Bank'
      }}
      boardData={projectRequestList}
      loading={loading}
    />
  );
}
