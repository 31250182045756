import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Button } from '@radix-ui/themes';
import { selectDevice } from 'store/slices/common';

import './style.scss';

export default function Error() {
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  const navigate = useNavigate();

  // 임시
  const isError404 = true;
  return (
    <Flex direction="column" align="center" justify="center" gap={isMobile ? 'var(--space-12)' : 'var(--space-20)'} className="error-page">
      <Flex direction="column" align="center" justify="center" gap="var(--space-7)">
        <img src={require('assets/images/common/error.svg').default} alt="에러페이지" />
        <Text as="p" align="center" className={`title${isMobile ? '6' : '4'}`}>
          {isError404
            ? // 404 에러일 때 문구입니다.
              '페이지를 찾을 수 없습니다.'
            : // 500 에러일 때 문구입니다.
              '웹 페이지를 표시할 수 없습니다.'}
        </Text>
        <>
          {isError404 ? (
            // 404 에러일 때 문구입니다.
            <Text as="p" color="gray" align="center" className={`body${isMobile ? '2' : '1'}`}>
              방문하시려는 페이지의 주소가 잘못 입력되었거나, {isMobile && <br />}변경 혹은 삭제되어 찾을 수 없습니다.
              <br />
              서비스 이용에 불편을 드려 죄송합니다.
            </Text>
          ) : (
            // 500 에러일 때 문구입니다.
            <Text as="p" color="gray" align="center" className={`body${isMobile ? '2' : '1'}`}>
              현재 사이트에서 요청을 처리할 수 없습니다.
            </Text>
          )}
        </>
      </Flex>
      <Button size={!isMobile && '3'} onClick={() => navigate('/')} className={!isMobile && 'btn-lg'}>
        홈으로 이동
      </Button>
    </Flex>
  );
}
