import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Box, Flex, Text, Grid } from '@radix-ui/themes';
import { useSelector } from 'react-redux';

import Visual from 'components/common/visual';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import BoardSearchBox from 'components/board/boardSearchBox';
import Pagination from 'components/board/pagination';
import { ImageTileItem } from 'components/board/boardItem';

import { selectDevice } from 'store/slices/common';
import './style.scss';
import LocalSort from 'components/common/sortByLocal';

const PAGE_SIZE = 8;

export default function BoardImage({ pageTitle, visualProps, boardData, loading }) {
  // hooks
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  const [searchParams, setSearchParams] = useSearchParams();

  // 검색 Method
  const getSearchText = ({ type, text }) => {
    searchParams.set('page', 1);
    searchParams.set('searchType', type);
    searchParams.set('search', text);
    setSearchParams(searchParams);
  };

  // 지역 선택 Method
  const setRegion = region => {
    searchParams.set('page', 1);
    searchParams.set('searchType', '');
    searchParams.set('search', '');
    searchParams.set('region', region);
    setSearchParams(searchParams);
  };

  // 페이지 이동 Method
  const handlePageChange = pageNo => {
    const totalPages = boardData?.pagination.totalPage;

    if (pageNo >= 1 && pageNo <= totalPages) {
      searchParams.set('page', pageNo);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <Visual category={visualProps.category} title={visualProps.title} eng={visualProps.eng} />
      <section className="board-image-wrap">
        {isMobile && (
          <>
            <BreadcrumbsBox />
            <PageTitle title={pageTitle} />
          </>
        )}
        <Container>
          {!isMobile && (
            <>
              <BreadcrumbsBox />
              <PageTitle title={pageTitle} />
            </>
          )}

          <Box>
            <LocalSort setRegion={setRegion} />
            <BoardSearchBox getSearchText={getSearchText} />
            {boardData?.pagination.totalCount > 0 ? (
              <>
                <Text as="p" mt={isMobile ? 'var(--space-9)' : 'var(--space-3)'} className="body2 font-basic">
                  전체 {boardData?.pagination.totalCount?.toLocaleString()}
                </Text>

                <Grid
                  columns={isMobile ? '1' : '4'}
                  gap="var(--space-6)"
                  pt={isMobile ? 'var(--space-5)' : 'var(--space-9)'}
                  className="board-image-items"
                >
                  <ImageTileItem data={boardData.list} />
                </Grid>

                <Flex justify="center" mt="var(--space-12)">
                  <Pagination totalPosts={boardData?.pagination.totalCount} postsPerPage={PAGE_SIZE} handlePageChange={handlePageChange} />
                </Flex>
              </>
            ) : (
              <Box py={!isMobile && 'var(--space-25)'} pt={isMobile && 'var(--space-16)'}>
                <Flex direction="column" align="center" justify="center" gap="var(--space-5)" className="no-data">
                  <img src={require('assets/images/icon/icon-nodata.svg').default} alt="데이터에러" />
                  <Text as="p" color="gray" className="body1">
                    데이터가 없습니다.
                  </Text>
                </Flex>
              </Box>
            )}
          </Box>
        </Container>
      </section>
    </>
  );
}
