import { combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

import * as slices from './slices';

export const rootReducer = combineReducers({
  common: slices.commonSlice.reducer,
  users: slices.userSlice.reducer
});
const persistConfig = {
  key: 'root',
  storage
};
export const persistedReducer = persistReducer(persistConfig, rootReducer);
