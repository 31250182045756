const days = [
  {
    chip: 'DAY 1',
    date: '2024. 12. 12(목)'
  },
  {
    chip: 'DAY 2',
    date: '2024. 12. 13(금)'
  }
];

const timeTable = {
  0: [
    {
      schedule: '그랜드 오프닝 (미디어쇼)',
      list: [
        {
          time: '14:00~14:05',
          duration: '5분',
          title: 'NIPA'
        }
      ],
      isBreak: false
    },
    {
      schedule: '행사 개회',
      list: [
        {
          time: '14:05-14:20',
          duration: '15분',
          title: '환영사',
          desc: '대구광역시'
        },
        { title: '격려사', desc: '과학기술정보통신부' },
        { title: '개회사', desc: 'NIPA 김득중 부원장' }
      ],
      isBreak: false
    },
    {
      schedule: '지역SW산업발전 유공자 및 공모전 시상식',
      badge: true,
      list: [
        {
          time: '14:20~14:50',
          duration: '30분',
          title: '정보통신산업진흥원장표창·상(12점)',
          desc: 'NIPA'
        },
        {
          title: '과학기술정보통신부장관표창·상(12점)',
          desc: '과학기술정보통신부'
        }
      ],
      isBreak: false
    },
    {
      schedule: 'Break Time',
      list: [
        {
          time: '14:50~15:20',
          duration: '30분',
          title: '주요 우수성과·사례 전시부스 체험',
          desc: '과학기술정보통신부·지자체·NIPA·지역SW산업진흥기관'
        }
      ],
      isBreak: false
    },
    {
      schedule: '정책 및 성과 발표',
      list: [
        {
          time: '15:20-15:35',
          duration: '15분',
          title: '국가 AI 전략 정책방향',
          desc: '과학기술정보통신부 이찬희 사무관'
        },
        {
          time: '15:35-15:50',
          duration: '15분',
          title: '’24년 주요성과 및 ’25년 사업 방향',
          desc: 'NIPA 지역디지털본부 정수진 본부장'
        },
        {
          time: '15:50-16:05',
          duration: '15분',
          title: '지역 디지털 사업 기획 컨설팅 성과 및 시사점',
          desc: 'NIPA 지역디지털기획팀 김현환 팀장'
        }
      ],
      isBreak: false
    },
    {
      schedule: 'Break Time',
      list: [{ time: '16:05-16:25', duration: '20분' }],
      breakTime: '20분',
      isBreak: true
    },
    {
      schedule: '’24년 성과 및 우수사례',
      badge: true,
      list: [
        {
          time: '16:25-16:35',
          duration: '10분',
          title: '인공지능중심산업융합집적단지 성과 및 향후 방향',
          desc: 'NIPA 지역디지털기반팀 윤정섭 팀장'
        },
        {
          time: '16:35-16:45',
          duration: '10분',
          title: '지역 디지털 브릿지 성과 및 협조 사항',
          desc: 'NIPA 지역디지털산업팀 유희숙 팀장'
        },
        {
          time: '16:45-16:55',
          duration: '10분',
          title: '빅테크 기업 교육 추진 성과 및 향후 방향',
          desc: 'NIPA 디지털인재양성팀 이상타 팀장'
        },
        {
          time: '16:55-17:05',
          duration: '10분',
          title: '경남 디지털 중소기업 대기업 상생협력을 통한 글로벌 진출 초석 마련',
          desc: '경남테크노파크 양승국 팀장'
        },
        {
          time: '17:05-17:15',
          duration: '10분',
          title: '대구-제주클러스터 초광역 연계협력 우수사례',
          desc: '대구디지털혁신진흥원 이영준 본부장'
        },
        {
          time: '17:15-17:25',
          duration: '10분',
          title: '지역산업 경쟁력 강화를 위한 해운물류 디지털화',
          desc: '울산정보산업진흥원 박현철 본부장'
        },
        {
          time: '17:25-17:35',
          duration: '10분',
          title: '프로젝트X 운영 성과 및 인턴십 등 기업 연계 사례',
          desc: '대구디지털혁신진흥원 안현우 팀장'
        }
      ],
      isBreak: false
    },
    {
      schedule: '지역 디지털 협력 사업 소개',
      list: [
        {
          time: '17:35-17:45',
          duration: '10분',
          title: '중소기업 클라우드 서비스 보급확산 사업 소개',
          desc: 'NIPA 클라우드산업팀 백성현 수석'
        },
        {
          time: '17:45-17:55',
          duration: '10분',
          title: '‘25년 NIPA 디지털 트윈 사업 설명',
          desc: 'NIPA 디지털신산업팀 (미정)'
        },
        {
          time: '17:55-18:05',
          duration: '10분',
          title: 'ICT 규제샌드박스 제도 설명',
          desc: 'NIPA 규제샌드박스팀 변완수 수석'
        },
        {
          time: '18:05-18:15',
          duration: '10분',
          title: '한국전파진흥협회 지역 디지털 사업 소개',
          desc: '한국전파진흥협회 양용열 사무총장'
        }
      ],
      isBreak: false
    },
    {
      schedule: '숙소 배정 및 석식',
      list: [{ time: '18:15-19:30', duration: '75분' }],
      isBreak: true
    },
    {
      schedule: '네트워킹 (소통의 장)',
      list: [{ time: '19:30-21:00', duration: '90분' }],
      isBreak: true
    }
  ],
  1: [
    {
      schedule: '지역 디지털 사업별 업무 회의',
      list: [
        {
          time: '10:00~12:00',
          duration: '80분',
          title: '제3차 지역 디지털 정책 얼라이언스',
          desc: 'NIPA'
        },
        { title: '디지털 혁신거점 협의체', desc: 'NIPA' }
      ],
      isBreak: false
    }
  ]
};

export { days, timeTable };
