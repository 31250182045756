import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@radix-ui/themes';
import { useSearchParams } from 'react-router-dom';

import BoardText from 'components/board/boardText';

import { instance } from 'api/axios.instance';

// 공지사항 > 성과보고회 우수 영상관
export default function VideoGallery() {
  const boardType = 'promotion';

  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [galleryList, setGalleryList] = useState({ list: [], pagination: {} }); // 홍보자료 목록
  const [loading, setLoading] = useState(true); // 로딩 상태

  // 테이블 헤더
  const tableHeader = [
    { label: 'NO', key: 'index', width: '80px' },
    { label: '제목', key: 'title', width: 'auto', left: true, className: 'has-detail', code: 'postNo' },
    { label: '작성자', key: 'writer', width: '108px' },
    { label: '작성일', key: 'createDate', width: '200px' },
    { label: '조회수', key: 'viewCount', width: '120px' }
  ];

  // 홍보자료 목록 조회 API
  const fetchGalleryData = useCallback(async () => {
    const searchTypeMap = {
      제목: 'title',
      내용: 'content'
    };

    setLoading(true);
    try {
      const list = await instance('/post/list', {
        method: 'POST',
        data: {
          boardType,
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          pageSize: 10, // 페이지 별 노출 개수
          searchType: searchTypeMap[searchParams.get('searchType')] || '', // 검색 카테고리
          search: searchParams.get('search') || '', // 검색어
          orderBy: 'post_no', // 정렬 기준
          inOrder: 'desc' // 정렬 순서 (오름차순 | 내림차순)
        }
      });
      setGalleryList({ list: list.data.data.posts, pagination: list.data.data.pagination });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchGalleryData();
  }, [searchParams, fetchGalleryData]);

  return (
    <Box>
      <BoardText
        pageTitle="우수 영상관"
        visualProps={{
          category: 'notice',
          title: '우수 영상관',
          eng: 'Video Gallery'
        }}
        tableHeader={tableHeader}
        tableBody={galleryList}
        loading={loading}
        boardType={boardType}
      />
    </Box>
  );
}
