import React, { useState } from 'react';
import { Flex, Button, Box, Text, Separator } from '@radix-ui/themes';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { selectDevice, selectSelectedAwardYear } from 'store/slices/common';
import { contestData, contestSwData, manOfMeritData } from './data';

import './style.scss';

export default function AwardTemplate({ type }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  const year = useSelector(selectSelectedAwardYear);

  const awardTab = [
    {
      title: '과학기술정보통신부 장관상',
      img: require('assets/images/pages/bestPractices/logo-science-technology.svg').default,
      alt: '과학기술정보통신부 로고 아이콘'
    },
    {
      title: '정보통신산업진흥원 원장상',
      img: require('assets/images/pages/bestPractices/logo-nipa.svg').default,
      alt: '정보통신산업진흥원 로고 아이콘'
    }
  ];

  const dataList = type !== 'contest' ? manOfMeritData : contestData;

  const handleButtonClick = index => {
    setActiveIndex(index);
  };

  return (
    <>
      <Flex direction={isMobile && 'column'} className={type !== 'contest' ? 'award-tabs merit' : 'award-tabs'}>
        {awardTab.map((item, index) => (
          <Button
            key={index}
            variant="outline"
            size="4"
            color="gray"
            className={classNames('award-button', {
              active: activeIndex === index
            })}
            onClick={() => handleButtonClick(index)}
          >
            <img src={item.img} alt={item.alt} />
            <Text as="p">{item.title}</Text>
          </Button>
        ))}
      </Flex>

      {dataList[year] && (
        <Box className="award-frame">
          <Box className="award-frame-gold">
            <Box className={type !== 'contest' ? 'award-frame-corner-top red' : 'award-frame-corner-top'}></Box>
            <Box className={type !== 'contest' ? 'award-frame-corner-bottom red' : 'award-frame-corner-bottom'}></Box>

            <Box className="award-wrap">
              <Flex direction="column" align={isMobile ? 'center' : 'end'} className="award-title">
                <Flex direction={isMobile && 'column'} gap={isMobile ? 'var(--space-1)' : 'var(--space-10)'} className="award-host">
                  <Flex align="center" gap="var(--space-2)">
                    <Text as="p" className={isMobile ? 'sub-title2' : 'title6'}>
                      주최
                    </Text>
                    <img
                      src={require('assets/images/pages/bestPractices/logo-science-technology-long.svg').default}
                      alt="과학기술정보통신부 로고 아이콘"
                    />
                  </Flex>
                  <Flex align="center" gap="var(--space-2)">
                    <Text as="p" className={isMobile ? 'sub-title2' : 'title6'}>
                      주관
                    </Text>
                    <img
                      src={require('assets/images/pages/bestPractices/logo-nipa-long.svg').default}
                      alt="정보통신산업진흥원 로고 아이콘"
                    />
                  </Flex>
                </Flex>
                <Text
                  as="p"
                  align={isMobile ? 'center' : 'right'}
                  mt={isMobile ? 'var(--space-17)' : 'var(--space-12)'}
                  className={isMobile ? 'title4' : 'title2'}
                >
                  {year} {isMobile && <br />}지역SW산업발전
                  <br />
                  {type !== 'contest' ? '유공자 포상 대상자' : '우수사례 공모전'}
                </Text>
                {activeIndex === 0 ? (
                  <Text as="p" mt={isMobile ? 'var(--space-7)' : 'var(--space-20)'} className={isMobile ? 'title7' : 'title4'}>
                    과학기술정보통신부
                    {type !== 'contest' ? ' 장관 표창' : ' 장관상'}
                  </Text>
                ) : (
                  <Text as="p" mt={isMobile ? 'var(--space-7)' : 'var(--space-20)'} className={isMobile ? 'title7' : 'title4'}>
                    정보통신산업진흥원
                    {type !== 'contest' ? ' 원장 표창' : ' 원장상'}
                  </Text>
                )}
              </Flex>

              <Flex direction="column" gap={isMobile ? 'var(--space-20)' : 'var(--space-15)'}>
                {dataList[year][activeIndex].map((el, idx) => (
                  <Flex direction={isMobile && 'column'} align="center" gap={isMobile ? 'var(--space-6)' : 'var(--space-20)'} key={idx}>
                    <Box className="award-img-box">
                      <img
                        src={require('assets/images/pages/bestPractices/frame-laurel.svg').default}
                        alt="월계수 프레임 이미지"
                        className="laurel-img"
                      />
                      <Flex align="center" justify="center" className="award-img">
                        {el.img ? (
                          <img src={el.img} alt={el.alt} />
                        ) : (
                          <img src={require('assets/images/pages/bestPractices/img-awardee-default.svg').default} alt="기본 이미지" />
                        )}
                      </Flex>
                    </Box>
                    <Flex direction="column" ml="auto" className="award-text-box">
                      <Text as="p" mb={isMobile ? 'var(--space-4)' : 'var(--space-6)'} className={isMobile ? 'title7' : 'title5'}>
                        {el.subject}
                      </Text>
                      <Flex
                        direction={isMobile && 'column'}
                        align={isMobile ? 'start' : 'center'}
                        mb={isMobile ? 'var(--space-2)' : 'var(--space-3)'}
                        gapX="var(--space-5)"
                      >
                        <Flex align="center">
                          <Text as="p" className={isMobile ? 'sub-title2 font-info' : 'sub-title1 font-info'} mr="var(--space-5)">
                            {el.type}
                          </Text>
                          {el.region && (
                            <>
                              <Text as="p" className={isMobile ? 'sub-title2' : 'sub-title1'}>
                                지역
                              </Text>
                              <Separator orientation="vertical" size="1" mx="var(--space-2)" />
                              <Text as="p" className={isMobile ? 'body2' : 'body1'}>
                                {el.region}
                              </Text>
                            </>
                          )}
                        </Flex>
                        {el.affiliation && (
                          <Flex align="center" gap="var(--space-2)">
                            <Text as="p" className={isMobile ? 'sub-title2' : 'sub-title1'}>
                              소속
                            </Text>
                            <Separator orientation="vertical" size="1" />
                            <Text as="p" className={isMobile ? 'body2' : 'body1'}>
                              {el.affiliation}
                            </Text>
                          </Flex>
                        )}
                        {el.period && (
                          <Flex align="center" gap="var(--space-2)">
                            <Text as="p" className={isMobile ? 'sub-title2' : 'sub-title1'}>
                              유공기간
                            </Text>
                            <Separator orientation="vertical" size="1" />
                            <Text as="p" className={isMobile ? 'body2' : 'body1'}>
                              {el.period}
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                      <Text as="p" className={isMobile ? 'body2 font-basic' : 'body1 font-basic'}>
                        {el.desc}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Box>
          </Box>
        </Box>
      )}

      {type === 'contest' && contestSwData[year] && activeIndex === 1 && (
        <Box className="award-frame">
          <Box className="award-frame-gold">
            <Box className={type !== 'contest' ? 'award-frame-corner-top red' : 'award-frame-corner-top'}></Box>
            <Box className={type !== 'contest' ? 'award-frame-corner-bottom red' : 'award-frame-corner-bottom'}></Box>

            <Box className="award-wrap">
              <Flex direction="column" align={isMobile ? 'center' : 'end'} className="award-title">
                <Flex direction={isMobile && 'column'} gap={isMobile ? 'var(--space-1)' : 'var(--space-10)'} className="award-host">
                  <Flex align="center" gap="var(--space-2)">
                    <Text as="p" className={isMobile ? 'sub-title2' : 'title6'}>
                      주최
                    </Text>
                    <img
                      src={require('assets/images/pages/bestPractices/logo-science-technology-long.svg').default}
                      alt="과학기술 정보통신부 로고 아이콘"
                    />
                  </Flex>
                  <Flex align="center" gap="var(--space-2)">
                    <Text as="p" className={isMobile ? 'sub-title2' : 'title6'}>
                      주관
                    </Text>
                    <img
                      src={require('assets/images/pages/bestPractices/logo-nipa-long.svg').default}
                      alt="정보통신산업진흥원 로고 아이콘"
                    />
                  </Flex>
                </Flex>
                <Text
                  as="p"
                  align={isMobile ? 'center' : 'right'}
                  mt={isMobile ? 'var(--space-17)' : 'var(--space-12)'}
                  className={isMobile ? 'title4' : 'title2'}
                >
                  {year} {isMobile && <br />}SW미래채움 {isMobile && <br />}센터 운영
                  <br />
                  우수사례 공모전
                </Text>
                <Text as="p" mt={isMobile ? 'var(--space-7)' : 'var(--space-20)'} className={isMobile ? 'title7' : 'title4'}>
                  정보통신 산업진흥원 원장상
                </Text>
              </Flex>

              <Flex direction="column" gap={isMobile ? 'var(--space-20)' : 'var(--space-15)'}>
                {contestSwData[year].map((el, idx) => (
                  <Flex direction={isMobile && 'column'} align="center" gap={isMobile ? 'var(--space-6)' : 'var(--space-20)'} key={idx}>
                    <Box className="award-img-box">
                      <img
                        src={require('assets/images/pages/bestPractices/frame-laurel.svg').default}
                        alt="월계수 프레임 이미지"
                        className="laurel-img"
                      />
                      {el.img ? (
                        <img src={el.img} alt={el.alt} className="award-img" />
                      ) : (
                        <img
                          src={require('assets/images/pages/bestPractices/img-awardee-default.svg').default}
                          alt="기본 이미지"
                          className="award-img"
                        />
                      )}
                    </Box>
                    <Flex direction="column" ml="auto" className="award-text-box">
                      <Text as="p" mb={isMobile ? 'var(--space-4)' : 'var(--space-6)'} className={isMobile ? 'title7' : 'title5'}>
                        {el.affiliation}
                      </Text>
                      <Flex align="center" mb={isMobile ? 'var(--space-2)' : 'var(--space-3)'}>
                        <Text as="p" mr="var(--space-5)" className={isMobile ? 'sub-title2 font-info' : 'sub-title1 font-info'}>
                          {el.type}
                        </Text>
                        {el.region && (
                          <>
                            <Text as="p" className={isMobile ? 'sub-title2' : 'sub-title1'}>
                              지역
                            </Text>
                            <Separator orientation="vertical" size="1" mx="var(--space-2)" />
                            <Text as="p" className={isMobile ? 'body2' : 'body1'}>
                              {el.region}
                            </Text>
                          </>
                        )}
                      </Flex>
                      <Text as="p" className={isMobile ? 'body2 font-basic' : 'body1 font-basic'}>
                        {el.desc}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
