import React, { Suspense } from 'react';
import { Box, Text, Section } from '@radix-ui/themes';

import './style.scss';

export default function ProjectDetail({ device, agendaData, projectComponents }) {
  const isMobile = device === 'mobile';
  return (
    <Section
      id="projectDetail"
      py={isMobile ? 'var(--space-4)' : 'var(--space-10)'}
      px={isMobile ? 'var(--space-4)' : 'var(--space-10)'}
      className={`solution-infographic-bg ${
        agendaData.stage === '1단계' ? 'gray' : agendaData.stage === '2단계' ? 'light-sky' : agendaData.stage === '3단계' ? 'sky' : ''
      }`}
    >
      <Text as="p" mb={isMobile ? 'var(--space-2)' : 'var(--space-5)'} className={isMobile ? 'title7' : 'title5'}>
        {agendaData.stage === '1단계'
          ? '지역현안'
          : agendaData.stage === '2단계'
            ? '아이디어'
            : agendaData.stage === '3단계'
              ? '프로젝트'
              : '아카이브'}
      </Text>

      <Box className="solution-infographic-contents">
        {/* 프로젝트 생성 시 작성된 아젠다 */}
        <Suspense>
          {projectComponents.map((Component, index) => (
            <div id={agendaData?.projectDetails[index].templateCode} key={index}>
              <Component index={index} templateData={agendaData?.projectDetails?.map(data => data.templateData)[index]} />
            </div>
          ))}
        </Suspense>
      </Box>
    </Section>
  );
}
