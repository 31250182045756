import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Flex, Box } from '@radix-ui/themes';

import './style.scss';
import { menuList } from 'components/layout/header';

export default function BreadcrumbsBox() {
  const location = useLocation();
  const currentPath = location.pathname;

  const [showFirstMenu, setShowFirstMenu] = useState(false);
  const [showSecondMenu, setShowSecondMenu] = useState(false);
  const containerRef = useRef(null);

  const findCurrentMenu = (menuList, currentPath) => {
    let currentMenu = null;
    let currentSubMenu = null;

    for (const menu of menuList) {
      if (currentPath.startsWith(menu.link)) {
        currentMenu = menu;
      }
      if (menu.subMenus) {
        for (const subMenu of menu.subMenus) {
          if (currentPath.startsWith(subMenu.link)) {
            currentMenu = menu;
            currentSubMenu = subMenu;
            break;
          }
        }
      }
      if (currentMenu && currentSubMenu) {
        break;
      }
    }

    return { currentMenu, currentSubMenu };
  };

  const { currentMenu, currentSubMenu } = findCurrentMenu(menuList, currentPath);

  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowFirstMenu(false);
        setShowSecondMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box className="breadcrumbs-box" ref={containerRef}>
      <Flex align="center">
        <Box className="breadcrumbs">
          <Flex align="center">
            {/* 메인 링크 */}
            <Link to="/" title="메인으로 이동" className="home">
              메인
            </Link>

            {/* 1depth 메뉴 */}
            {currentMenu?.menu && (
              <div className="button-wrap">
                <button type="button" className="body2" onClick={() => setShowFirstMenu(!showFirstMenu)}>
                  {currentMenu.menu}
                </button>
                {showFirstMenu && (
                  <ul>
                    {menuList.map(menu => (
                      <li key={menu.id}>
                        <Link to={menu.link} className={`body2 ${menu.id === currentMenu.id ? 'selected' : ''}`} title={menu.title}>
                          {menu.menu}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            {/* 2depth 메뉴 */}
            {currentSubMenu?.menu && (
              <div className="button-wrap">
                <button type="button" className="body2" onClick={() => setShowSecondMenu(!showSecondMenu)}>
                  {currentSubMenu.menu}
                </button>
                {showSecondMenu && (
                  <ul>
                    {currentMenu?.subMenus?.map(sub => (
                      <li key={sub.link}>
                        <Link to={sub.link} className={`body2 ${sub.link === currentSubMenu.link ? 'selected' : ''}`} title={sub.title}>
                          {sub.menu}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
