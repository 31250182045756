import React from 'react';
import { cardData } from './data';
import BoardCard from 'components/board/boardCard';

export default function Performance() {
  return (
    <BoardCard
      pageTitle="사업별 성과 현황"
      visualProps={{
        category: 'business',
        title: '사업',
        eng: 'Dreams Business'
      }}
      data={cardData}
    />
  );
}
