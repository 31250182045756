export const localList = [
  { name: '전체', code: '' },
  { name: '강원', code: 'GW' },
  { name: '경기', code: 'GG' },
  { name: '경남', code: 'GN' },
  { name: '경북', code: 'GB' },
  { name: '광주', code: 'GJ' },
  { name: '대구', code: 'DG' },
  { name: '울산', code: 'US' },
  { name: '인천', code: 'IC' },
  { name: '전남', code: 'JN' },
  { name: '전북', code: 'JB' },
  { name: '제주', code: 'JJ' },
  { name: '충남', code: 'CN' },
  { name: '충북', code: 'CB' }
];
export const yearList = ['2024년', '2023년', '2022년', '2021년', '2020년'];
export const regions = [
  { label: '서울', value: 'SU' },
  { label: '경기기타', value: 'GG' },
  { label: '경기', value: 'GG' },
  { label: '경기용인', value: 'YI' },
  { label: '경기고양', value: 'GY' },
  { label: '인천', value: 'IC' },
  { label: '대전', value: 'DJ' },
  { label: '세종', value: 'SJ' },
  { label: '충북', value: 'CB' },
  { label: '충남', value: 'CN' },
  { label: '광주', value: 'GJ' },
  { label: '전남', value: 'JN' },
  { label: '전북', value: 'JB' },
  { label: '대구', value: 'DG' },
  { label: '경북', value: 'GB' },
  { label: '부산', value: 'BS' },
  { label: '울산', value: 'US' },
  { label: '경남', value: 'GN' },
  { label: '강원', value: 'GW' },
  { label: '제주', value: 'JJ' }
];
