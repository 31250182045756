import React from 'react';
import Visual from 'components/common/visual/index';
import { Container, Box, Text } from '@radix-ui/themes';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

import './style.scss';

const AboutDreams = () => {
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  return (
    <>
      <Visual category="introduction" title="소개" eng="Dreams Introduction" />
      <section className="about-dreams">
        <Container>
          <BreadcrumbsBox />
          <PageTitle title="DREAMS 란?" />
          <Box>
            <Text as="p" className={`body${isMobile ? '2' : '1'} title-desc`}>
              <strong className="color-primary">D</strong>igitized <strong className="color-primary">RE</strong>gional{' '}
              <strong className="color-primary">A</strong>dvancement <strong className="color-primary">M</strong>echanism{' '}
              <strong className="color-primary">S</strong>ystem
            </Text>

            <Text as="p" className={`title${isMobile ? '6' : '5'} slim sub-page-title`}>
              지역 디지털산업 관련, 지역의 성장 핵심 주체 대상으로&nbsp;
              {isMobile ? null : <br />}
              정책·지원·성과·통계 등 맞춤형 정보공유 및 성과 확산·홍보 위한&nbsp;
              {isMobile ? null : <br />}
              <strong className="color-primary">디지털 분야에 특화된 지역 통합 정보공유포털</strong>
            </Text>
          </Box>
          <Box className="activator-box">
            <div className="title-circle">
              <span className={`title title${isMobile ? '6' : '5'}`}>
                지역 디지털 혁신
                <br />
                생태계 활성화
              </span>
            </div>
            {isMobile ? null : (
              <div className="trees">
                <div className="root">
                  <div className="branch"></div>
                </div>
                <div className="branches">
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                </div>
              </div>
            )}
            <div className="cards">
              <div className="card">
                <div className="card-header">
                  <Text as="p" className={`title${isMobile ? '7' : '6'}`}>
                    디지털 DATA 공유
                  </Text>
                </div>
                <div className="card-body">
                  <Text as="p" align="center" className={`body${isMobile ? '2' : '1'}`}>
                    정부(중앙-지방)의 지역 디지털 성과물 및<br /> 지역별 보유 데이터·플랫폼,
                    <br /> 사업 등 검색·활용
                  </Text>
                  <Text as="p" align="center" className="body3" mt="6">
                    旣 지역별 디지털 플랫폼 연계를 통해서
                    <br /> 지역 디지털 혁신 사업으로 생성되는 분산된
                    <br /> 지역 디지털·특화 산업의 DATA를 통합 관리·공유
                  </Text>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <Text as="p" className={`title${isMobile ? '7' : '6'}`}>
                    성과 홍보·확산
                  </Text>
                </div>
                <div className="card-body">
                  <Text as="p" align="center" className={`body${isMobile ? '2' : '1'}`}>
                    全지역의 디지털 사업, 성과의
                    <br /> 정보공시를 통해 사업·지역별 중복(유사성)
                    <br /> 검토 기능 제공 및 지역間
                    <br /> 우수성과·사업공모 홍보
                  </Text>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <Text as="p" className={`title${isMobile ? '7' : '6'}`}>
                    지역 디지털 통계 서비스
                  </Text>
                </div>
                <div className="card-body">
                  <Text as="p" align="center" className={`body${isMobile ? '2' : '1'}`}>
                    정부의 지역 디지털 현황 및 산업
                    <br /> 실태조사 및 유관 지역 디지털 정보 등<br /> 지역 디지털 실태정보
                    <br /> 통계 분석·제공
                  </Text>
                </div>
              </div>
            </div>
            <Box align="center" my={`${isMobile ? 'var(--space-12)' : '9'}`}>
              <img
                src={require('assets/images/pages/introduction/img-about-dreams-arrow.svg').default}
                alt="지역 디지털산업 정보공유체계를 통한 데이터 축적 설명 화살표"
              />
            </Box>
            <Text as="p" weight="bold" className={`title${isMobile ? '6' : '5'}`} align="center">
              <strong className="color-primary">지역 디지털산업 정보공유체계</strong>를 통한 데이터 축적
            </Text>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default AboutDreams;
