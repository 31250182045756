import React from 'react';
import './style.scss';
import { Container, Flex } from '@radix-ui/themes';

export default function Visual(props) {
  return (
    <section className={`visual ${props.category}`}>
      <Container>
        <Flex align="end" className="title">
          <h2 className="title2">{props.title}</h2>
          <p className="copy1">{props.eng}</p>
        </Flex>
      </Container>
    </section>
  );
}
