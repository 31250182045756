import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import { editorConfig } from './editorConfig';
import 'ckeditor5/ckeditor5.css';
import './style.scss';

// CKEditor 컴포넌트
export default function Editor({ editorData }) {
  // state
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => {
      if (editorRef.current?.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
      setIsLayoutReady(false);
    };
  }, []);

  return (
    <div className="main-container">
      <div className="editor-container editor-container_document-editor editor-container_include-minimap" ref={editorContainerRef}>
        <div className="editor-container__minimap-wrapper">
          <div className={`editor-container__editor-wrapper read-only`}>
            <div ref={editorRef} className="editor-container__editor">
              {isLayoutReady && (
                <CKEditor
                  editor={editorConfig.editor}
                  config={{ ...editorConfig.config }}
                  data={editorData}
                  onReady={editor => {
                    editorRef.current = editor;
                    editor.enableReadOnlyMode('readOnlyMode');
                    return;
                  }}
                  onDestroy={() => {
                    editorRef.current = null;
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
