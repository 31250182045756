import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Box, Button, Flex, IconButton, Link, Separator, Text } from '@radix-ui/themes';
import { selectDevice } from 'store/slices/common';
import { instance } from 'api/axios.instance';
import Visual from 'components/common/visual';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import './style.scss';
import Editor from 'components/editor';

export default function BoardDetail({ pageTitle, visualProps, boardType }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  const [postDetail, setPostDetail] = useState({});

  const fetchPostDetail = useCallback(async () => {
    const response = await instance(`/post/${id}`, {
      method: 'GET'
    });
    setPostDetail(response.data.data);
  }, [id]);

  useEffect(() => {
    fetchPostDetail();
  }, [fetchPostDetail]);

  const handleDownload = async file => {
    try {
      const response = await fetch(file.fileUrl);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', file.originalName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const handleDownloadAll = async () => {
    for (const file of postDetail.files) {
      await handleDownload(file);
    }
  };

  return (
    <>
      <Visual category={visualProps.category} title={visualProps.title} eng={visualProps.eng} />
      <section>
        <Container>
          <BreadcrumbsBox />
          <PageTitle title={pageTitle} />

          <Box>
            <Flex justify={'center'} className="post-title" py="var(--space-6)">
              <Text as="p" align={'center'} className={`title${isMobile ? '6' : '4'}`}>
                {postDetail.post?.title}
              </Text>
            </Flex>
            <Flex
              className="post-info"
              align="center"
              gap={isMobile ? '3' : '5'}
              px={isMobile ? '4' : 'var(--space-12)'}
              py={isMobile && '2'}
              // direction={isMobile ? 'column' : 'row'}
              height={!isMobile && 'var(--space-13)'}
            >
              <Text as="p" className={`body${isMobile ? '3' : '2'}`}>
                작성자 : 관리자
              </Text>
              {!isMobile && <Separator size="1" orientation="vertical" />}
              {isMobile ? (
                <>
                  <Text as="p" className={`body${isMobile ? '3' : '2'}`}>
                    {postDetail.post?.createDate}
                  </Text>
                  <Flex align="center" ml="auto" gap="var(--space-1)">
                    <img src={require('assets/images/icon/icon-view.svg').default} alt="조회수 아이콘" />
                    <Text as="p" className="body3">
                      {postDetail.post?.viewCount}
                    </Text>
                  </Flex>
                </>
              ) : (
                <>
                  <Text as="p" className="body2">
                    게재일 : {postDetail.post?.createDate}
                  </Text>
                  <Separator size="1" orientation="vertical" />
                  <Text as="p" className="body2">
                    조회수 : {postDetail.post?.viewCount}
                  </Text>
                </>
              )}
            </Flex>

            {postDetail.post?.content && (
              <Box
                className="post-box"
                py={isMobile && '5'}
                pt={!isMobile && 'var(--space-15)'}
                pb={!isMobile && 'var(--space-15)'}
                px={isMobile ? '4' : 'var(--space-12)'}
              >
                <Box className="post-content">
                  <Editor editorData={postDetail.post?.content || ''} />
                </Box>
              </Box>
            )}

            {postDetail.files?.length > 0 && (
              <Box className="post-attachment" pt={isMobile ? '5' : 'var(--space-15)'}>
                <Flex justify="between">
                  <Flex align="center" gap="var(--space-2)" className={`attachment-title sub-title${isMobile ? '2' : '1'}`}>
                    <img src={require('assets/images/icon/icon-clip.svg').default} alt="첨부파일 아이콘" />
                    첨부파일
                  </Flex>
                  {isMobile ? null : (
                    <Flex className="attachment-menu" align={'center'} gap={'2'}>
                      <Button onClick={handleDownloadAll} className="rt-variant-text">
                        전체 다운로드
                        <img src={require('assets/images/icon/icon-download.svg').default} alt="다운로드 아이콘" />
                      </Button>
                    </Flex>
                  )}
                </Flex>
                <Flex direction={'column'} gap={'2'} mt={isMobile ? '5' : '4'} className="attachment-list">
                  {postDetail.files.map((file, index) =>
                    isMobile ? (
                      <Flex key={index} direction={'column'} gap={'3'} p={'4'} className="list-item">
                        <Flex align={'center'} justify={'between'}>
                          <span className={`icon icon-pdf`} />
                          <Flex gap={'3'} ml={'auto'}>
                            <IconButton onClick={() => handleDownload(file)} className="rt-variant-text">
                              <img src={require('assets/images/icon/icon-download.svg').default} alt="다운로드 아이콘" />
                            </IconButton>
                            <IconButton onClick={() => window.open(file.fileUrl, '_blank')} className="rt-variant-text">
                              <img src={require('assets/images/icon/icon-folder.svg').default} alt="폴더 아이콘" />
                            </IconButton>
                          </Flex>
                        </Flex>
                        <Link href={file.fileUrl}>{file.originalName}</Link>
                      </Flex>
                    ) : (
                      <Flex key={index} align={'center'} gap={'3'} py={'3'} px={'7'} className="list-item">
                        <span className={`icon icon-pdf`} />
                        <Link href={file.fileUrl}>{file.originalName}</Link>
                        <Flex gap={'3'} ml={'auto'}>
                          <IconButton onClick={() => handleDownload(file)} className="rt-variant-text" title="다운로드">
                            <img src={require('assets/images/icon/icon-download.svg').default} alt="다운로드 아이콘" />
                          </IconButton>
                          <IconButton onClick={() => window.open(file.fileUrl, '_blank')} className="rt-variant-text" title="파일열기">
                            <img src={require('assets/images/icon/icon-folder.svg').default} alt="폴더 아이콘" />
                          </IconButton>
                        </Flex>
                      </Flex>
                    )
                  )}
                </Flex>
              </Box>
            )}

            {isMobile ? (
              <Flex mt={'5'} align="center" className="post-navigation">
                {postDetail.nextPost && (
                  <IconButton
                    variant="outline"
                    onClick={() => navigate(`/${boardType}/detail/${postDetail.nextPost?.postNo}`)}
                    title="이전"
                  />
                )}
                <Button onClick={() => navigate(`/${boardType}`)} className="register">
                  목록
                </Button>
                {postDetail.prevPost && (
                  <IconButton
                    ml="auto"
                    variant="outline"
                    onClick={() => navigate(`/${boardType}/detail/${postDetail.prevPost?.postNo}`)}
                    title="다음"
                  />
                )}
              </Flex>
            ) : (
              <>
                <Box className="post-navigation" mt={'var(--space-15)'}>
                  {postDetail.nextPost && (
                    <Box className="post-navigation-item">
                      <span>이전글</span>
                      <Text onClick={() => navigate(`/${boardType}/detail/${postDetail.nextPost?.postNo}`)} as="p">
                        {postDetail.nextPost?.title}
                      </Text>
                    </Box>
                  )}
                  {postDetail.prevPost && (
                    <Box className="post-navigation-item">
                      <span>다음글</span>
                      <Text onClick={() => navigate(`/${boardType}/detail/${postDetail.prevPost?.postNo}`)} as="p">
                        {postDetail.prevPost?.title}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Flex justify={'end'} mt={'var(--space-15)'}>
                  <Button onClick={() => navigate(`/${boardType}`)} className="register">
                    목록
                  </Button>
                </Flex>
              </>
            )}
          </Box>
        </Container>
      </section>
    </>
  );
}
