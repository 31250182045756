import React from 'react';
import { Heading } from '@radix-ui/themes';

import './pageTitle.scss';

export default function PageTitle(props) {
  return (
    <Heading as="h3" className={`title3 page-title ${props.icon}`}>
      {props.title}
    </Heading>
  );
}
