import React, { Suspense } from 'react';
import { Box, Text, Section } from '@radix-ui/themes';

import './style.scss';

export default function DocsDetail({ index, device, agendaData, docsComponent }) {
  const isMobile = device === 'mobile';
  return (
    <Section
      id={`docsDetail_${index}`}
      py={isMobile ? 'var(--space-4)' : 'var(--space-10)'}
      px={isMobile ? 'var(--space-4)' : 'var(--space-10)'}
      className={`solution-infographic-bg ${
        agendaData.stage === '1단계' ? 'gray' : agendaData.stage === '2단계' ? 'light-sky' : agendaData.stage === '3단계' ? 'sky' : ''
      }`}
    >
      <Text as="p" mb={isMobile ? 'var(--space-2)' : 'var(--space-5)'} className={isMobile ? 'title7' : 'title5'}>
        {agendaData?.docsDetails[index]?.title}
      </Text>

      <Box className="solution-infographic-contents">
        {/* 아젠다 요청으로 작성된 아젠다 */}
        <Suspense>
          {docsComponent?.map((Component, idx) => (
            <div id={agendaData?.docsDetails[index]?.datas[idx].templateCode} key={idx}>
              <Component index={idx} templateData={agendaData?.docsDetails[index]?.datas?.map(data => data.templateData)[idx]} />
            </div>
          ))}
        </Suspense>
      </Box>
    </Section>
  );
}
