import React from 'react';
import { Container } from '@radix-ui/themes';

import Visual from 'components/common/visual';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import SortByYear from 'components/common/sortByYear';
import AwardTemplate from 'components/common/award';

export default function Contest() {
  return (
    <>
      <Visual category="practices" title="우수사례" eng="Best Practices" />
      <section className="best-practices">
        <Container>
          <BreadcrumbsBox />
          <PageTitle title="공모전" />
          <SortByYear path="award" />
          <AwardTemplate type="contest" />
        </Container>
      </section>
    </>
  );
}
