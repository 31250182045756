import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Box, Heading, Flex, Text } from '@radix-ui/themes';

import Visual from 'components/common/visual';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import Aside from 'components/layout/aside/Aside';
import ProjectDetail from 'components/solution/ProjectDetail';

import { selectDevice } from 'store/slices/common';
import { instance } from 'api/axios.instance';
import { useParams } from 'react-router-dom';
import DocsDetail from 'components/solution/DocsDetail';

export default function ProjectListDetail() {
  // hooks
  const device = useSelector(selectDevice);
  const isMobile = device === 'mobile';
  const { id } = useParams();

  // state
  const [agendaData, setAgendaData] = useState({}); // 프로젝트 상세 데이터
  const [projectComponents, setProjectComponents] = useState([]); // 동적 컴포넌트
  const [docsComponents, setDocsComponents] = useState([]); // 동적 컴포넌트
  const [loading, setLoading] = useState(true);

  // 동적 컴포넌트 렌더링 Method
  const handleRenderComponent = async (type, componentNames) => {
    let arr = [];

    for (const componentName of componentNames) {
      const { default: LoadedComponent } = await import(`components/template/${componentName}`);
      if (type === 'project') {
        arr.push(LoadedComponent);
      } else if (type === 'docs') {
        arr.push(LoadedComponent);
      }
    }

    if (type === 'project') {
      setProjectComponents(arr);
    } else if (type === 'docs') {
      setDocsComponents(prev => [...prev, arr]);
    }
  };

  // 프로젝트 상세 조회 API
  const fetchProjectById = useCallback(async () => {
    setLoading(true);
    try {
      const response = await instance(`/project/whole/${id}`, {
        method: 'GET'
      });

      setProjectComponents([]);
      setDocsComponents([]);
      if (response.data.status === 200) {
        if (response.data.data.projectDetails?.length > 0) {
          handleRenderComponent(
            'project',
            response.data.data.projectDetails?.map(data => data.componentName)
          );
        }

        if (response.data.data.docsDetails?.length > 0) {
          for (const docDetail of response.data.data.docsDetails) {
            await handleRenderComponent(
              'docs',
              docDetail.datas.map(data => data.componentName)
            );
          }
        }

        setAgendaData(response.data.data);
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  // mount 시 호출
  useEffect(() => {
    fetchProjectById();
  }, [fetchProjectById]);
  return (
    <>
      <Visual category="solution" title="솔루션 뱅크" eng="Solution Bank" />
      <section>
        <Container className={isMobile && 'px-0'}>
          <BreadcrumbsBox />
          {isMobile ? (
            <Box px="var(--space-5)">
              <PageTitle title="프로젝트 목록" />
            </Box>
          ) : (
            <PageTitle title="프로젝트 목록" />
          )}
          <Flex gap="var(--space-6)">
            <Aside agendaData={agendaData} />

            <Box className="solution-infographic-wrap">
              {/* title */}
              <Flex
                direction={isMobile && 'column'}
                align={isMobile ? 'start' : 'center'}
                py={isMobile ? 'var(--space-5)' : 'var(--space-10)'}
                pl={isMobile ? 'var(--space-4)' : 'var(--space-15)'}
                pr={isMobile && 'var(--space-4)'}
                className="solution-infographic-title"
              >
                <Box>
                  <Heading as={isMobile ? 'h6' : 'h3'} mb="var(--space-6)" className={isMobile ? 'title6' : 'title3'}>
                    {agendaData?.title}
                  </Heading>
                  <Text as="p" className={isMobile ? 'sub-title3' : 'sub-title1'}>
                    {agendaData?.description}
                  </Text>
                </Box>
                {/* 임시 처리*/}
                <img
                  src={agendaData?.thumbnail?.fileUrl || require('assets/images/pages/solution/icon-info-title.svg').default}
                  alt="인포그래픽 정보 아이콘"
                  style={{
                    marginLeft: 'auto',
                    width: '446px',
                    height: '360px'
                  }}
                />
              </Flex>

              {/* infographic - template */}
              {/* {selectedMenu === 0 ? (
                                <SolutionGuide2 device={device} />
                            ) : ( */}
              {loading ? (
                <></>
              ) : (
                <>
                  <ProjectDetail device={device} agendaData={agendaData} projectComponents={projectComponents} />
                  {docsComponents.map((docsComponent, index) => (
                    <DocsDetail key={index} index={index} device={device} agendaData={agendaData} docsComponent={docsComponent} />
                  ))}
                </>
              )}
              {/* )} */}
            </Box>
          </Flex>
        </Container>
      </section>
    </>
  );
}
