import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE;

export const defaultHeaders = {
  'Content-Type': 'application/json'
};

export const createCustomInstance = (customURL, config = defaultHeaders) => {
  return axios.create({
    baseURL: customURL,
    headers: config,
    timeout: 5000, // 시간이 오래 걸리는 요청에 대한 timeout 값 재정의, 5초
    withCredentials: true
  });
};

// API instance
export const instance = createCustomInstance(baseURL);

/**
 * API 호출 전 공통 로직
 */
instance.interceptors.request.use(
  config => {
    console.debug(`request => ${config.url}`, config);
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
