import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Flex } from '@radix-ui/themes';
import { useSearchParams } from 'react-router-dom';

import { localList } from 'app/constants';
import { selectDevice } from 'store/slices/common';

import './style.scss';

export default function SortByLocal({ setRegion }) {
  // hooks
  const [searchParams] = useSearchParams();
  const device = useSelector(selectDevice);
  const isMobile = device === 'mobile';

  // state
  const [active, setActive] = useState(null);
  const classList = isMobile ? 'sub-title2 ' : 'sub-title1 rt-r-size-lg ';

  // 지역 클릭 이벤트 Method
  const handleClick = i => {
    setActive(i);
    setRegion(localList[i].code);
  };
  // mount 시 searchParams에 맞는 지역 활성화
  useEffect(() => {
    const localIndex = localList.map(data => data.code).indexOf(searchParams.get('region'));
    localIndex >= 0 ? setActive(localIndex) : setActive(0);
  }, [searchParams]);

  return (
    <>
      <Flex align="center" justify="between" gap="var(--space-2)" mb="var(--space-6)" className="sort-by-local">
        {localList.map((el, i) => {
          return (
            <Button size="4" key={i} className={classList + (active === i ? 'active' : '')} onClick={() => handleClick(i)}>
              {el.name}
            </Button>
          );
        })}
      </Flex>
    </>
  );
}
