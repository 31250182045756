import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box, Text } from '@radix-ui/themes';

import { useSelector, useDispatch } from 'react-redux';
import { selectDevice, setSelectAsideMenu } from 'store/slices/common';

import './aside.scss';

export default function Aside({ agendaData }) {
  const dispatch = useDispatch();
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';

  const [selectIdx, setSelectIdx] = useState(0);
  const handleSelect = (i, elementId) => {
    setSelectIdx(i);
    scrollToElement(`#${elementId}`);
    dispatch(setSelectAsideMenu(i));
  };

  const scrollToElement = element => {
    const section = document.querySelector(element);
    console.log(section);
    if (!section) return;

    const yOffset = 0;
    const y = section.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <>
      {!isMobile && (
        <Flex direction="column" className="aside-wrap">
          <Box py="var(--space-6)" px="var(--space-6)" className="aside-title">
            <Text as="p">{agendaData.title}</Text>
          </Box>

          <Box py="var(--space-7)" px="var(--space-6)">
            <ul className="aside-menu">
              <li className="aside-menu-item">
                <Link
                  title={agendaData.title}
                  className={selectIdx === 0 ? 'body1 active' : 'body1'}
                  onClick={() => handleSelect(0, 'projectDetail')}
                >
                  {agendaData.stage === '1단계'
                    ? '지역현안'
                    : agendaData.stage === '2단계'
                      ? '아이디어'
                      : agendaData.stage === '3단계'
                        ? '프로젝트'
                        : '아카이브'}
                </Link>
              </li>

              {agendaData?.docsDetails?.map((docsDetail, i) => (
                <li key={i} className="aside-menu-item">
                  <Link
                    title={docsDetail.title}
                    className={selectIdx === i + 1 ? 'body1 active' : 'body1'}
                    onClick={() => handleSelect(i + 1, `docsDetail_${i}`)}
                  >
                    {docsDetail.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Box>
        </Flex>
      )}
    </>
  );
}
