import { useDispatch } from 'react-redux';
import { setDevice } from 'store/slices/common';

export const useDevice = () => {
  const varUA = navigator.userAgent.toLowerCase();
  const isTouchDevice = navigator.maxTouchPoints || 'ontouchstart' in document.documentElement;
  const htmlElement = document.querySelector('html');

  const dispatch = useDispatch();

  if (varUA.indexOf('android') > -1 || varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
    //안드로이드 & IOS
    htmlElement.setAttribute('data-device', 'mobile');
    dispatch(setDevice('mobile'));
  } else {
    if (isTouchDevice >= 1 && varUA.indexOf('windows') < 0 && varUA.indexOf('macintosh') < 0) {
      // touch 가능 노트북 제외 추가
      htmlElement.setAttribute('data-device', 'mobile');
      dispatch(setDevice('mobile'));
    } else {
      //  pc
      htmlElement.setAttribute('data-device', 'pc');
      dispatch(setDevice('pc'));
    }
  }
};
