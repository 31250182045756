import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Text, Flex, Button, Separator } from '@radix-ui/themes';

import { selectDevice } from 'store/slices/common';
import { loginUser, selectErrorInfo, selectStopReason, setError } from 'store/slices/users';

import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import Visual from 'components/common/visual';
import TextInput from 'components/common/textInput';

import './style.scss';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const errorInfo = useSelector(selectErrorInfo);
  const stopReason = useSelector(selectStopReason);
  const deviceType = useSelector(selectDevice);

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const isMobile = deviceType === 'mobile';

  // 에러 메시지
  const ERROR_MESSAGES = {
    invalidCredentials: (
      <>
        아이디(로그인 전용 아이디) 또는 비밀번호를 잘못 입력했습니다. <br />
        입력하신 내용을 다시 확인해주세요.
      </>
    ),
    accountSuspended: (
      <>
        입력하신 계정은 사용 불가 처리가 되었습니다.
        <br />
        {/*사유: 보직 이동으로 인한 사용 정지*/}
        사유: {stopReason}
      </>
    )
  };

  const handleId = id => {
    if (errorInfo !== null) {
      dispatch(setError(null));
    }
    setId(id);
  };

  const handlePassword = pw => {
    if (errorInfo !== null) {
      dispatch(setError(null));
    }
    setPassword(pw);
  };

  const handleLogin = async e => {
    e.preventDefault();
    if (!id || !password) {
      dispatch(setError('invalidCredentials'));
      return;
    }
    const result = await dispatch(loginUser({ id, password }));
    if (result.error) {
      dispatch(setError('invalidCredentials'));
    } else {
      navigate(from, { replace: true });
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleLogin(e); // 엔터 키 입력 시 handleLogin 호출
    }
  };

  useEffect(() => {
    dispatch(setError(null));
  }, [dispatch]);

  return (
    <>
      <Visual category="solution" title="솔루션 뱅크" eng="Solution Bank" />
      <section className="login-wrap">
        <Container>
          <BreadcrumbsBox />
          <PageTitle title="DREAMS 관리자 로그인" />
          <Text as="p" color="gray" align={isMobile ? 'left' : 'center'} className={isMobile ? 'body2' : 'body1'}>
            로그인을 하시면 다양한 솔루션 뱅크를 편리하게 보실 수 있어요 😀
          </Text>

          <Box mt={isMobile ? 'var(--space-16)' : 'var(--space-25)'}>
            <Flex direction="column" align="center" justify="center">
              <form className="login-box" onSubmit={handleLogin} onKeyDown={handleKeyDown}>
                <TextInput size="3" id="id" type="text" value={id} placeholder="아이디를 입력해주세요." onChange={handleId} />

                <TextInput
                  size="3"
                  id="password"
                  type="password"
                  password={password}
                  placeholder="비밀번호를 입력해주세요."
                  onChange={handlePassword}
                />

                {/* 애러 처리 */}
                {errorInfo && (
                  <Flex align="start" mt="var(--space-2)" mb="var(--space-5)" gap="var(--space-1)" className="error-msg">
                    <Box>
                      <img src={require('assets/images/icon/icon-error.svg').default} alt="에러아이콘" />
                    </Box>
                    <Text as="p" className="body3 font-error">
                      {ERROR_MESSAGES[errorInfo]}
                    </Text>
                  </Flex>
                )}

                <Button size="3" my="var(--space-6)" type="submit" className="fluid">
                  LOGIN
                </Button>

                <Flex align="center" justify="center" gap="var(--space-4)">
                  <button className={isMobile ? 'sub-title2' : 'sub-title1'} onClick={() => navigate('/findId')}>
                    아이디 찾기
                  </button>
                  <Separator orientation="vertical" size="1" />
                  <button className={isMobile ? 'sub-title2' : 'sub-title1'} onClick={() => navigate('/findPassword')}>
                    비밀번호 찾기
                  </button>
                </Flex>
              </form>
            </Flex>
          </Box>
        </Container>
      </section>
    </>
  );
}
