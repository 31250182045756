import React from 'react';
import { Dialog, IconButton, VisuallyHidden } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectErrorDialogStatus, setIsErrorDialogOpen } from 'store/slices/common';
import './style.scss';

export const ErrorDialog = () => {
  const dispatch = useDispatch();
  const status = useSelector(selectErrorDialogStatus);
  return (
    <>
      <Dialog.Content maxWidth="400px" className="basic-dialog">
        <IconButton onClick={() => dispatch(setIsErrorDialogOpen(false))} className="rt-variant-text close-btn">
          <Cross1Icon />
        </IconButton>

        <VisuallyHidden>
          <Dialog.Title align="center">서버 에러 안내</Dialog.Title>
        </VisuallyHidden>

        <Dialog.Description align="center" size="2">
          {status} <br /> 서버 에러가 발생했습니다. <br /> 잠시 후 다시 시도해 주세요.
        </Dialog.Description>
      </Dialog.Content>
    </>
  );
};

export default ErrorDialog;
