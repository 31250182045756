import React from 'react';
import { Box, Flex, Table, Text } from '@radix-ui/themes';
import './style.scss';
import { useSearchParams } from 'react-router-dom';

const TableType = ({ tableHeader, tableData, onCellClick, totalCount }) => {
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;
  return (
    <Table.Root size="3">
      <caption>텍스트 게시판 리스트 타입</caption>
      <Table.Header>
        <Table.Row align="center">
          {tableHeader?.map((header, index) => {
            return (
              <Table.ColumnHeaderCell key={index} justify="center" width={header.width}>
                {header.label}
              </Table.ColumnHeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tableData?.map((post, rowIndex) => (
          <Table.Row key={rowIndex} align="center">
            {tableHeader?.map((header, colIndex) => (
              <Table.Cell
                key={colIndex}
                justify={header.left ? 'start' : 'center'}
                data-title={header.label}
                onClick={() => (header.className === 'has-detail' ? onCellClick(post[header.code]) : () => {})}
                className={header.className}
              >
                {header.key === 'index' && Math.max(totalCount - ((currentPage - 1) * 10 + rowIndex), 1)}
                {header.key === 'writer' && '관리자'}
                {post[header.key]}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

const TileType = ({ tableData, onCellClick, totalCount }) => {
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;
  return (
    <Box className="table-list-type">
      {tableData?.map((post, rowIndex) => (
        <Flex
          onClick={() => onCellClick(post.postNo)}
          key={rowIndex}
          direction="column"
          pt="var(--space-5)"
          pb="var(--space-3)"
          gap="var(--space-3)"
        >
          <Flex align="center" justify="between" gap="var(--space-4)">
            <Text as="p" className="sub-title2 font-basic">
              {Math.max(totalCount - ((currentPage - 1) * 10 + rowIndex), 1)}
            </Text>
          </Flex>
          <Text as="p" className="body1 font-basic">
            {post.title}
          </Text>
          <Flex align="center" justify="between" gap="var(--space-4)">
            <Text as="p" className="body2 font-basic">
              {post.createDate}
            </Text>
            <Flex align="center" gap="var(--space-1)">
              <img src={require('assets/images/icon/icon-view.svg').default} alt="조회수 아이콘" />
              <Text as="p" className="body2 font-basic">
                {post.viewCount}
              </Text>
            </Flex>

            <Box ml="auto">{post.isFile && <img src={require('assets/images/icon/icon-clip.svg').default} alt="첨부파일 아이콘" />}</Box>
          </Flex>
        </Flex>
      ))}
    </Box>
  );
};

export default function ListType({ isMobile, tableHeader, tableData, onCellClick, totalCount }) {
  return (
    <>
      {!isMobile ? (
        <TableType totalCount={totalCount} tableHeader={tableHeader} tableData={tableData} onCellClick={onCellClick} />
      ) : (
        <TileType totalCount={totalCount} tableData={tableData} onCellClick={onCellClick} />
      )}
    </>
  );
}
