import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';
import { instance } from 'api/axios.instance';
import { Box, Container, Flex, Text, Separator, VisuallyHidden } from '@radix-ui/themes';
// import { MainAccordion } from 'components/Accordion';
import { BannerSlider } from 'components/common/slider';

import './style.scss';

export default function Main() {
  const device = useSelector(selectDevice);
  const isPc = device === 'pc';
  const [noticeList, setNoticeList] = useState([]);
  const fetchNoticeList = useCallback(async () => {
    const response = await instance('/post/list', {
      method: 'POST',
      data: {
        boardType: 'notice',
        pageNo: 1,
        pageSize: 3,
        inOrder: 'DESC'
      }
    });
    setNoticeList(response.data.data.posts);
  }, []);

  useEffect(() => {
    fetchNoticeList();
  }, [fetchNoticeList]);

  const newNoticeList = noticeList.slice(0, 3);

  return (
    <>
      {/*<section>/!* <MainSlider /> *!/</section>*/}
      <section className="top-banner">
        <Container>
          <Flex direction={!isPc && 'column'} align={isPc && 'center'} gap={isPc ? 'var(--space-20)' : 'var(--space-12)'}>
            <Text as="p" className={isPc ? 'title6' : 'sub-title1'}>
              지역 디지털 산업 맞춤형 {!isPc && <br />}정보 공유 및 성과 확산·홍보를 위한 <br />
              <Text as="span" className={isPc ? 'title6' : 'sub-title1'}>
                지역 특화 통합 정보 공유 포털
              </Text>
            </Text>
            <img src={require('assets/images/pages/main/bg-main.svg').default} alt="배너이미지" />
          </Flex>
        </Container>
      </section>
      <section className="main">
        <Container>
          {/* main banner */}
          {isPc ? (
            <Flex mt="var(--space-25" className="main-banner">
              {/* 거점·기술 */}
              <Box className="banner-item type-dark bg-primary">
                <Box className="banner-text">
                  <Box>
                    <Text as="p" className="title5">
                      거점·기술
                    </Text>
                    <Text as="p" className="body2">
                      지역 디지털 혁신 생태계 조성
                    </Text>
                  </Box>
                  <Separator orientation="horizontal" />
                  <ul>
                    <li>
                      <Link
                        to="business/performance/detail?id=0"
                        title="인공지능중심산업융합집적단지로 이동"
                        state={'인공지능중심산업융합집적단지'}
                      >
                        인공지능중심산업융합집적단지
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=1"
                        state={'디지털 혁신거점 조성지원'}
                        title="디지털 혁신거점 조성지원으로 이동"
                      >
                        디지털 혁신거점 조성지원
                      </Link>
                    </li>
                  </ul>
                </Box>
                <Box className="banner-img">
                  <img src={require('../../assets/images/pages/main/img-technology.svg').default} alt="거점·기술 이미지" />
                </Box>
              </Box>
              {/* 인적자원 */}
              <Box className="banner-item type-light">
                <Box className="banner-text">
                  <Box>
                    <Text as="p" className="title5">
                      인적자원
                    </Text>
                    <Text as="p" className="body2">
                      지역혁신 선도 디지털 인재 양성체계 구축
                    </Text>
                  </Box>
                  <Separator orientation="horizontal" />
                  <ul>
                    <li>
                      <Link to="/business/performance/detail?id=4" state={'스마트물류 기술 실증화'} title="스마트물류 기술 실증화로 이동">
                        스마트물류 기술 실증화
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=5"
                        state={'농식품 분야 메타버스 기반 기술 실증지원'}
                        title="농식품 분야 메타버스 기반 기술 실증지원으로 이동"
                      >
                        농식품 분야 메타버스 기반 기술 실증지원
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=6"
                        state={'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용'}
                        title="AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용으로 이동"
                      >
                        AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용
                      </Link>
                    </li>
                  </ul>
                </Box>
                <Box className="banner-img">
                  <img src={require('../../assets/images/pages/main/img-resource.svg').default} alt="인적자원 이미지" />
                </Box>
              </Box>
              {/* 실증·보급 */}
              <Box className="banner-item type-dark bg-navy">
                <Box className="banner-text">
                  <Box>
                    <Text as="p" className="title5">
                      실증·보급
                    </Text>
                    <Text as="p" className="body2">
                      디지털 기반 지역경제 · 사회 혁신지원
                    </Text>
                  </Box>
                  <Separator orientation="horizontal" />
                  <ul>
                    <li>
                      <Link to="/business/performance/detail?id=4" state={'스마트물류 기술 실증화'} title="스마트물류 기술 실증화로 이동">
                        스마트물류 기술 실증화
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=5"
                        state={'농식품 분야 메타버스 기반 기술 실증지원'}
                        title="농식품 분야 메타버스 기반 기술 실증지원으로 이동"
                      >
                        농식품 분야 메타버스 기반 기술 실증지원
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=6"
                        state={'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용'}
                        title="AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용으로 이동"
                      >
                        AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=7"
                        state={'지역 디지털 기초체력 지원'}
                        title="지역 디지털 기초체력 지원으로 이동"
                      >
                        지역 디지털 기초체력 지원
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=8"
                        state={'제조업 AI융합 기반 조성'}
                        title="제조업 AI융합 기반 조성으로 이동"
                      >
                        제조업 AI융합 기반 조성
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=9"
                        state={'AI융합 지능형 농업 생태계 구축'}
                        title="AI융합 지능형 농업 생태계 구축으로 이동"
                      >
                        AI융합 지능형 농업 생태계 구축
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business/performance/detail?id=10"
                        state={'AI기반 중량화물이동체 물류플랫폼 실증'}
                        title="AI기반 중량화물이동체 물류플랫폼 실증으로 이동"
                      >
                        AI기반 중량화물이동체 물류플랫폼 실증
                      </Link>
                    </li>
                  </ul>
                </Box>
                <Box className="banner-img">
                  <img src={require('../../assets/images/pages/main/img-dissemination.svg').default} alt="실증·보급 이미지" />
                </Box>
              </Box>
            </Flex>
          ) : (
            <Box mt="var(--space-16)" className="main-banner">
              <BannerSlider />
            </Box>
          )}

          {/*  link - 임시 주석 처리 */}
          {/* <Flex direction={!isPc && 'column'} mt={isPc ? 'var(--space-21)' : 'var(--space-16)'} className="business-items">
                        <Box className="item">
                            <Link to="/business/survey" title="2023 지역 실태조사으로 이동">
                                <Text as="p" className={`title${isPc ? '6' : '7'}`}>
                                    2023 지역 실태조사
                                </Text>
                                <Text as="p" className={`view ${isPc && 'sub-title2'}`}>
                                    VIEW
                                </Text>
                            </Link>
                        </Box>
                        <Box className="item">
                            <Link to="/" title="2024 사업 추진 현황으로 이동">
                                <Text as="p" className={`title${isPc ? '6' : '7'}`}>
                                    2024 사업 추진 현황
                                </Text>
                                <Text as="p" className={`view ${isPc && 'sub-title2'}`}>
                                    VIEW
                                </Text>
                            </Link>
                        </Box>
                        <Box className="item">
                            <Link to="/business/performance" title="사업 성과로 이동">
                                <Text as="p" className={`title${isPc ? '6' : '7'}`}>
                                    사업 성과
                                </Text>
                                <Text as="p" className={`view ${isPc && 'sub-title2'}`}>
                                    VIEW
                                </Text>
                            </Link>
                        </Box>
                    </Flex> */}

          {/* notice */}
          <Flex direction={!isPc && 'column'} align="start" className="main-info">
            <Flex direction="column" gap="var(--space-15)" className="info-title">
              <Box>
                {isPc ? (
                  <Text as="p" mb="var(--space-2)" className="title4">
                    공지사항
                  </Text>
                ) : (
                  <Link to="/notice" title="공지사항으로 이동">
                    <Text as="p" className="title5">
                      공지사항
                    </Text>
                    <Text as="p" className="more-btn">
                      <VisuallyHidden>공지사항으로 바로가기</VisuallyHidden>
                    </Text>
                  </Link>
                )}
                <Text as="p" className="body2 font-basic">
                  사업진행 상황을 솔루션뱅크를 통해 확인하세요.
                </Text>
              </Box>

              {isPc && (
                <Link to="/notice" title="공지사항으로 이동" className="more-btn">
                  <Text as="p" className="sub-title2">
                    MORE
                  </Text>
                  <VisuallyHidden>공지사항으로 바로가기</VisuallyHidden>
                </Link>
              )}
            </Flex>

            <Box className="info-items">
              {newNoticeList.map((value, index) => (
                <Link key={index} to={`/notice/detail/${value.postNo}`} title="공지사항으로 이동" className="info-point">
                  <Flex direction="column" align="center" justify="center" className="date">
                    {!isPc && <Text className="sub-title2 font-basic">{value.createDate}</Text>}
                    <Text as="p" className={`font-dark title${isPc ? '4' : '3'}`}>
                      {`0${index + 1}`}
                    </Text>
                    {isPc && <Text className="sub-title2 font-basic">{value.createDate}</Text>}
                  </Flex>

                  <Box>
                    <Text as="p" className="title7 title font-dark">
                      {value.title}
                    </Text>
                  </Box>
                </Link>
              ))}
            </Box>

            <Link to="/videoGallery" title="성과보고회 우수 영상관으로 이동" className="info-video">
              <Flex direction="column" gap="var(--space-9)">
                <Box>
                  <Text as="p" mb="var(--space-2)" className="title5">
                    성과보고회 우수 영상관
                  </Text>
                  <Text as="p" className="body2">
                    사업진행 상황을 솔루션뱅크를 통해 확인하세요.
                  </Text>
                </Box>
                <img src={require('assets/images/pages/main/img-video.svg').default} alt="우수 영상관 아이콘" />
              </Flex>
            </Link>
          </Flex>

          <Flex mt={isPc ? 'var(--space-21)' : 'var(--space-16)'} className="introduction-items">
            <Box className="item dreams">
              <Link to="/introduction/aboutDreams" title="DREAMS 소개으로 이동">
                DREAMS 소개<span>DREAMS 아이콘</span>
              </Link>
            </Box>
            <Box className="item policy">
              <Link to="/introduction/aboutDreams" title="추진체계로 이동">
                추진체계<span>추진체계 아이콘</span>
              </Link>
            </Box>
            <Box className="item sw">
              <Link to="/bestPractices/performanceReport" title="성과보고회로 이동">
                성과보고회<span>성과보고회 아이콘</span>
              </Link>
            </Box>
            <Box className="item best">
              <Link to="/bestPractices/contest" title="우수사례로 이동">
                우수사례<span>우수사례 아이콘</span>
              </Link>
            </Box>
          </Flex>
        </Container>
      </section>
    </>
  );
}
